<template>

    <div>
      <p ><u><strong>Hiya PAYG</strong></u> </p>
      <br>
      <div class="result-block-container">

        <div :class="['result-block', executed ? 'show' : '']">
        <b-form @submit="onSubmit" class="w-100">
            <p v-if="errors.length">
            <b>Please correct the following error(s):</b>
            <ul>
            <li v-for="error in this.errors" :vid-id="error" :key="error">{{ error }}</li>
            </ul>
        </p>
        <b-form-row>
          <b-col>
            <!-- <p>Tier 1</p> -->
            <b-form-group id="form-PaymentTerm-group" label="Tier 1" label-for="form-PaymentTerm-input"
              label-cols="1" label-cols-lg="10"  label-class="font-weight-bold pt-0" label-size="lg"  content-cols-sm="6" label-align-sm="right">
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-InterMinCharge-group" label="From" label-for="form-InterMinCharge-input"
            label-cols="2" label-cols-lg="3" label-size="sm" content-cols-sm="6" label-align-sm="right">
              <b-form-input id="form-InterMinCharge-input" type="text" size="sm"
                required v-model="editHiyaPAYGForm.Tier1From" >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-InterMinCharge-group" label="To" label-for="form-InterMinCharge-input"
            label-cols="2" label-cols-lg="3" label-size="sm" content-cols-sm="6" label-align-sm="right">
              <b-form-input id="form-InterMinCharge-input" type="text" size="sm"
                required v-model="editHiyaPAYGForm.Tier1To">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-InterMinCharge-group" label="Rate" label-for="form-InterMinCharge-input"
            label-cols="2" label-cols-lg="3"  label-size="sm" content-cols-sm="6" label-align-sm="right">
              <b-form-input id="form-InterMinCharge-input" type="text" size="sm"
                required v-model="editHiyaPAYGForm.Tier1Rate">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-InterMinCharge-group" label="NetX Rate" label-for="form-InterMinCharge-input"
            label-cols="2" label-cols-lg="5" label-size="sm" content-cols-sm="6" label-align-sm="right">
              <b-form-input id="form-InterMinCharge-input" type="text" size="sm" length="100"
                required v-model="editHiyaPAYGForm.Tier1NetXRate">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <!-- <p>Tier 1</p> -->
            <b-form-group id="form-PaymentTerm-group" label="Tier 2" label-for="form-PaymentTerm-input"
              label-cols="2" label-cols-lg="10"  label-class="font-weight-bold pt-0" label-size="lg"  content-cols-sm="1" label-align-sm="right" >
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-InterMinCharge-group" label="From" label-for="form-InterMinCharge-input"
            label-cols="2" label-cols-lg="3" label-size="sm" content-cols-sm="6" label-align-sm="right"  >
              <b-form-input id="form-InterMinCharge-input" type="text" size="sm"
                required v-model="editHiyaPAYGForm.Tier2From">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-InterMinCharge-group" label="To" label-for="form-InterMinCharge-input"
            label-cols="2" label-cols-lg="3" label-size="sm" content-cols-sm="6" label-align-sm="right" >
              <b-form-input id="form-InterMinCharge-input" type="text" size="sm"
                required v-model="editHiyaPAYGForm.Tier2To">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-InterMinCharge-group" label="Rate" label-for="form-InterMinCharge-input"
            label-cols="2" label-cols-lg="3"  label-size="sm" content-cols-sm="6" label-align-sm="right">
              <b-form-input id="form-InterMinCharge-input" type="text" size="sm"
                required v-model="editHiyaPAYGForm.Tier2Rate">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-InterMinCharge-group" label="NetX Rate" label-for="form-InterMinCharge-input"
            label-cols="2" label-cols-lg="5" label-size="sm" content-cols-sm="6" label-align-sm="right">
              <b-form-input id="form-InterMinCharge-input" type="text" size="sm" length="100"
                required v-model="editHiyaPAYGForm.Tier2NetXRate">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <!-- <p>Tier 1</p> -->
            <b-form-group id="form-PaymentTerm-group" label="Tier 3" label-for="form-PaymentTerm-input"
              label-cols="1" label-cols-lg="10"  label-class="font-weight-bold pt-0" label-size="lg"  content-cols-sm="6" label-align-sm="right">
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-InterMinCharge-group" label="From" label-for="form-InterMinCharge-input"
            label-cols="2" label-cols-lg="3" label-size="sm" content-cols-sm="6" label-align-sm="right">
              <b-form-input id="form-InterMinCharge-input" type="text" size="sm"
                required v-model="editHiyaPAYGForm.Tier3From">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-InterMinCharge-group" label="To" label-for="form-InterMinCharge-input"
            label-cols="2" label-cols-lg="3" label-size="sm" content-cols-sm="6" label-align-sm="right">
              <b-form-input id="form-InterMinCharge-input" type="text" size="sm"
                required v-model="editHiyaPAYGForm.Tier3To">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-InterMinCharge-group" label="Rate" label-for="form-InterMinCharge-input"
            label-cols="2" label-cols-lg="3"  label-size="sm" content-cols-sm="6" label-align-sm="right">
              <b-form-input id="form-InterMinCharge-input" type="text" size="sm"
                required v-model="editHiyaPAYGForm.Tier3Rate">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-InterMinCharge-group" label="NetX Rate" label-for="form-InterMinCharge-input"
            label-cols="2" label-cols-lg="5" label-size="sm" content-cols-sm="6" label-align-sm="right">
              <b-form-input id="form-InterMinCharge-input" type="text" size="sm" length="100"
                required v-model="editHiyaPAYGForm.Tier3NetXRate">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <!-- <p>Tier 1</p> -->
            <b-form-group id="form-PaymentTerm-group" label="Tier 4" label-for="form-PaymentTerm-input"
              label-cols="1" label-cols-lg="10"  label-class="font-weight-bold pt-0" label-size="lg"  content-cols-sm="6" label-align-sm="right">
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-InterMinCharge-group" label="From" label-for="form-InterMinCharge-input"
            label-cols="2" label-cols-lg="3" label-size="sm" content-cols-sm="6" label-align-sm="right">
              <b-form-input id="form-InterMinCharge-input" type="text" size="sm"
                required v-model="editHiyaPAYGForm.Tier4From">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-InterMinCharge-group" label="To" label-for="form-InterMinCharge-input"
            label-cols="2" label-cols-lg="3" label-size="sm" content-cols-sm="6" label-align-sm="right">
              <b-form-input id="form-InterMinCharge-input" type="text" size="sm"
                required v-model="editHiyaPAYGForm.Tier4To">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-InterMinCharge-group" label="Rate" label-for="form-InterMinCharge-input"
            label-cols="2" label-cols-lg="3"  label-size="sm" content-cols-sm="6" label-align-sm="right">
              <b-form-input id="form-InterMinCharge-input" type="text" size="sm"
                required v-model="editHiyaPAYGForm.Tier4Rate">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-InterMinCharge-group" label="NetX Rate" label-for="form-InterMinCharge-input"
            label-cols="2" label-cols-lg="5" label-size="sm" content-cols-sm="6" label-align-sm="right">
              <b-form-input id="form-InterMinCharge-input" type="text" size="sm" length="100"
                required v-model="editHiyaPAYGForm.Tier4NetXRate">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <!-- <p>Tier 1</p> -->
            <b-form-group id="form-PaymentTerm-group" label="Tier 5" label-for="form-PaymentTerm-input"
              label-cols="1" label-cols-lg="10"  label-class="font-weight-bold pt-0" label-size="lg" content-cols-sm="6" label-align-sm="right">
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-InterMinCharge-group" label="From" label-for="form-InterMinCharge-input"
            label-cols="2" label-cols-lg="3" label-size="sm" content-cols-sm="6" label-align-sm="right">
              <b-form-input id="form-InterMinCharge-input" type="text" size="sm"
                required v-model="editHiyaPAYGForm.Tier5From">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-InterMinCharge-group" label="To" label-for="form-InterMinCharge-input"
            label-cols="2" label-cols-lg="3" label-size="sm" content-cols-sm="6" label-align-sm="right">
              <b-form-input id="form-InterMinCharge-input" type="text" size="sm"
                required v-model="editHiyaPAYGForm.Tier5To">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-InterMinCharge-group" label="Rate" label-for="form-InterMinCharge-input"
            label-cols="2" label-cols-lg="3"  label-size="sm" content-cols-sm="6" label-align-sm="right">
              <b-form-input id="form-InterMinCharge-input" type="text" size="sm"
                required v-model="editHiyaPAYGForm.Tier5Rate">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-InterMinCharge-group" label="NetX Rate" label-for="form-InterMinCharge-input"
            label-cols="2" label-cols-lg="5" label-size="sm" content-cols-sm="6" label-align-sm="right">
              <b-form-input id="form-InterMinCharge-input" type="text" size="sm" length="100"
                required v-model="editHiyaPAYGForm.Tier5NetXRate">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <b-button-group>
                <b-button type="submit" variant="primary">Submit</b-button>
                <!-- <b-button type="reset" variant="danger">Reset</b-button> -->
              </b-button-group>
            </div>
          </div>
        </div>
        <!-- <b-button type="submit" variant="primary">Find</b-button> -->
  </b-form>

        </div>
      </div>

    </div>
  </template>

  <script>
  import Alert from '../components/Alert.vue';

import {isAdmin, editButtonText} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
      editHiyaPAYGForm: {
        HPAYGId :  null ,
        Tier1From :  null ,
        Tier1To :  null ,
        Tier1Rate :  null ,
        Tier1NetXRate :  null ,
        Tier2From :  null ,
        Tier2To :  null ,
        Tier2Rate :  null ,
        Tier2NetXRate :  null ,
        Tier3From :  null ,
        Tier3To :  null ,
        Tier3Rate :  null ,
        Tier3NetXRate :  null ,
        Tier4From :  null ,
        Tier4To :  null ,
        Tier4Rate :  null ,
        Tier4NetXRate :  null ,
        Tier5From :  null ,
        Tier5To :  null ,
        Tier5Rate :  null ,
        Tier5NetXRate :  null ,
        ModifiedOn :  null ,
        ModifiedBy  :  null 
        },
        DilalledNumber: null,
        apiPricedCalls: [],
        executed: false,
        message: 'initial alert',
        currentSort:'Date',
        currentSortDir:'asc',
        pageSize:20,
        currentPage:1,
        filter:'',
        showresult: false,
        showdownload:false,
        errors: []
      };
    },

    computed:{
    },
    methods: {
    onChangeCallType(event) {
            this.SelectedCallType= event.target.value
            },

    onChangeDirection(event) {
        this.SelectedDirection= event.target.value
        },

    onSubmit(evt) {
        evt.preventDefault();
        console.log("in onSubmitUpdateXXXXXXXXXXXXXXXXXXXXXXX");
        console.log("~~~~~~~~~~~~~~~~~~~~~~~ERROR~~~~~~~~~~~~~~~~~~~~");
        // run validations
        if (this.editHiyaPAYGForm.Tier1To <= this.editHiyaPAYGForm.Tier1From){
            alert('Tier1 "To" and "From" incorrect');
            evt.preventDefault();
            return false;
            // this.errors.push('Tier1 "To" and "From" incorrect');
        }
        if (this.editHiyaPAYGForm.Tier2From - this.editHiyaPAYGForm.Tier1To != 1){
            alert('Tier2 "From" and Tier1"To" incorrect');
            evt.preventDefault();
            return false;
            // this.errors.push('Tier1 "To" and "From" incorrect');
        }
        if (this.editHiyaPAYGForm.Tier2To <= this.editHiyaPAYGForm.Tier2From){
            alert('Tier2 "To" and "From" incorrect');
            evt.preventDefault();
            return false;
            // this.errors.push('Tier1 "To" and "From" incorrect');
        }
        if (this.editHiyaPAYGForm.Tier3From - this.editHiyaPAYGForm.Tier2To != 1){
            alert('Tier3 "From" and Tier2"To" incorrect');
            evt.preventDefault();
            return false;
            // this.errors.push('Tier1 "To" and "From" incorrect');
        }
        if (this.editHiyaPAYGForm.Tier3To <= this.editHiyaPAYGForm.Tier3From){
            alert('Tier3 "To" and "From" incorrect');
            evt.preventDefault();
            return false;
            // this.errors.push('Tier1 "To" and "From" incorrect');
        }
        if (this.editHiyaPAYGForm.Tier4From - this.editHiyaPAYGForm.Tier3To != 1){
            alert('Tier4 "From" and Tier3"To" incorrect');
            evt.preventDefault();
            return false;
            // this.errors.push('Tier1 "To" and "From" incorrect');
        }
        if (this.editHiyaPAYGForm.Tier4To <= this.editHiyaPAYGForm.Tier4From){
            alert('Tier4 "To" and "From" incorrect');
            evt.preventDefault();
            return false;
            // this.errors.push('Tier1 "To" and "From" incorrect');
        }
        if (this.editHiyaPAYGForm.Tier5From - this.editHiyaPAYGForm.Tier4To != 1){
            alert('Tier5 "From" and Tier4"To" incorrect');
            evt.preventDefault();
            return false;
            // this.errors.push('Tier1 "To" and "From" incorrect');
        }
        if (this.editHiyaPAYGForm.Tier5To <= this.editHiyaPAYGForm.Tier5From){
            alert('Tier5 "To" and "From" incorrect');
            evt.preventDefault();
            return false;
            // this.errors.push('Tier1 "To" and "From" incorrect');
        }
        
        
        console.log(this.errors)

        const payload = {
                    Tier1From :  this.editHiyaPAYGForm.Tier1From ,
                    Tier1To :  this.editHiyaPAYGForm.Tier1To,
                    Tier1Rate :  this.editHiyaPAYGForm.Tier1Rate ,
                    Tier1NetXRate :  this.editHiyaPAYGForm.Tier1NetXRate ,
                    Tier2From :  this.editHiyaPAYGForm.Tier2From ,
                    Tier2To :  this.editHiyaPAYGForm.Tier2To ,
                    Tier2Rate :  this.editHiyaPAYGForm.Tier2Rate ,
                    Tier2NetXRate :  this.editHiyaPAYGForm.Tier2NetXRate ,
                    Tier3From :  this.editHiyaPAYGForm.Tier3From ,
                    Tier3To :  this.editHiyaPAYGForm.Tier3To ,
                    Tier3Rate :  this.editHiyaPAYGForm.Tier3Rate ,
                    Tier3NetXRate :  this.editHiyaPAYGForm.Tier3NetXRate ,
                    Tier4From :  this.editHiyaPAYGForm.Tier4From ,
                    Tier4To :  this.editHiyaPAYGForm.Tier4To ,
                    Tier4Rate :  this.editHiyaPAYGForm.Tier4Rate ,
                    Tier4NetXRate :  this.editHiyaPAYGForm.Tier4NetXRate ,
                    Tier5From :  this.editHiyaPAYGForm.Tier5From ,
                    Tier5To :  this.editHiyaPAYGForm.Tier5To ,
                    Tier5Rate :  this.editHiyaPAYGForm.Tier5Rate ,
                    Tier5NetXRate :  this.editHiyaPAYGForm.Tier5NetXRate 
                    };

        this.updateHiyaPAYG(payload);
        this.callApi()
        
        
        // if (!this.errors.length) {
        //     console.log('~~~~~~~~~~~~~~~~~~~~~~~ERROR A~~~~~~~~~~~~~~~~~~~')
        //     this.errors = []
        //     this.updateHiyaPAYG(payload);
        //     this.callApi()
             
        // }
        // else{
        //     console.log('~~~~~~~~~~~~~~~~~~~~~~~ERROR B~~~~~~~~~~~~~~~~~~~~')
        //     evt.preventDefault(); 
        // }
        },
      // *******************************************
      //  UPDATE
      // *******************************************
      async updateHiyaPAYG(payload) {

        console.log('in update gammamarkup');
        console.log(payload);
        try {
        const { data } =  this.$http.put(`${process.env.VUE_APP_API_BASE_URL}/api/v1/hiyapayg`, payload).then(() => {
            this.message = data;
            this.showMessage = true;
            this.callApi();

        }).catch((err) => {
            if(err.response.status == 409) {
            alert("Duplicated gammamarkup. status code:  " + err.response.status)
            }
            if(err.response.status != 409) {
            alert("Problem with add gammamarkup. status code:  " + err.response.status)
            }

            console.error(err);
            this.callApi();

        });

        // console.log(data)
        this.callApi()
        } catch (e) {
        this.apiGiacomMarkups = `Error: the server responded with '${e.response}'`;
        }
        },
      // *******************************************
      // GET
      // *******************************************
    async callApi() {

        console.log('getting the data')
        var url;
        try {
          url = process.env.VUE_APP_API_BASE_URL + '/api/v1/hiyapayg';
          console.log(url)
          const { data } = await this.$http.get(url);
        //   console.log(data);
          this.editHiyaPAYGForm = data;
          this.showresult = true
          this.executed = true;
        } catch (e) {
          this.apiPricedCalls = `Error: the server responded with '${e.response}'`;
        }
        document.body.style.cursor = 'auto';
      },

    },

    created() {
      console.log('created')
      this.callApi();

    },
    components: {
      alert: Alert
    },
  };
  </script>
