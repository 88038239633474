<template>

  <div class="nav-container mb-3">
    <nav class="navbar navbar-expand-md navbar-light bg-light">
      <div class="container-fluid">
        <div class="navbar-brand" src="/britannic.jpeg">
        </div>
        <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
          aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button> -->

        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <img class="listImage" src="/logo.bmp">
            </li>
            <li class="nav-item dropdown">
              <router-link to="/" class="nav-link">Home</router-link>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                Accounts
              </a>
              <div class="dropdown-menu" text-nowrap>
            <li class="nav-item">
              <router-link to="/accounts" class="nav-link">Single Accounts</router-link>
            </li>
            <li class="nav-item" text-nowrap>
              <router-link to="/accountgroups" text-nowrap class="nav-link">Group Accounts</router-link>
            </li>
            <li class="nav-item" text-nowrap>
              <router-link to="/netxgroups" text-nowrap class="nav-link">NetX Group Accounts</router-link>
            </li>
            <li class="nav-item" text-nowrap>
              <router-link to="/industrytypes" text-nowrap class="nav-link">Industry Types</router-link>
            </li>
        </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
            Cost Centres
          </a>
          <div class="dropdown-menu" text-nowrap>
        <li class="nav-item">
          <router-link to="/costcentres" class="nav-link">Cost Centres</router-link>
        </li>
      </div>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
          Billing References
        </a>
        <div class="dropdown-menu" text-nowrap>
      <li class="nav-item" text-nowrap>
        <router-link to="/outbounds/search" text-nowrap class="nav-link">Outbound Billing References</router-link>
      </li>
      <li class="nav-item">
        <router-link to="/inbounds/search" class="nav-link">Inbound Billing References</router-link>
      </li>

  </div>
  </li>
  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
      Products
    </a>
    <div class="dropdown-menu" text-nowrap>
  <li class="nav-item" text-nowrap>
    <router-link to="/carriers" text-nowrap class="nav-link">Carriers</router-link>
  </li>
  <li class="nav-item" text-nowrap>
    <router-link to="/categories" class="nav-link">Categories</router-link>
  </li>
  <li class="nav-item" text-nowrap>
    <router-link to="/products" text-nowrap class="nav-link">Product Catalogue</router-link>
  </li>
  <li class="nav-item" text-nowrap>
    <router-link to="/recurringcharges/search" class="nav-link">Recurring Charge Inventory</router-link>
  </li>
  </div>
  </li>
  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown"> Rates </a>
    <div class="dropdown-menu" text-nowrap>
  <li class="nav-item" text-nowrap>
    <router-link to="/chargebands" text-nowrap class="nav-link">Base Rates - Domestic</router-link>
  </li>
  <li class="nav-item" text-nowrap>
    <router-link to="/chargebandcodes" text-nowrap class="nav-link">Dial Plan - Domestic</router-link>
  </li>
  <li class="nav-item" text-nowrap>
    <router-link to="/accountchargebands" text-nowrap class="nav-link">Bespoke Rates - Domestic</router-link>
  </li>
  <li class="nav-item">
    <router-link to="/countries" class="nav-link" text-nowrap>Base Rates - International</router-link>
  </li>
  <li class="nav-item">
    <router-link to="/countrycodes" class="nav-link" text-nowrap>Dial Plan - International</router-link>
  </li>
  <li class="nav-item">
    <router-link to="/accountcountries" class="nav-link" text-nowrap>Bespoke Rates - International</router-link>
  </li>
  <li class="nav-item" text-nowrap>
    <router-link to="/gammamobilemarkup" class="nav-link" text-nowrap>Gamma Mobile</router-link>
  </li>
  <li class="nav-item" text-nowrap>
    <router-link to="/giacommarkup" class="nav-link" text-nowrap>Giacom Mobile</router-link>
  </li>
  <li class="nav-item" text-nowrap>
    <router-link to="/pangeamarkup" class="nav-link" text-nowrap>Pangea</router-link>
  </li>
  </div>
  </li>
  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
      Bundle
    </a>
    <div class="dropdown-menu" text-nowrap>
  <!-- <li class="nav-item" text-nowrap>
    <router-link to="/mobilebundles" text-nowrap class="nav-link">Mobile bundles</router-link>
  </li> -->
  <li class="nav-item">
    <router-link to="/hiyabundles" class="nav-link">Hiya Bundles</router-link>
  </li>
  <li class="nav-item">
    <router-link to="/accounthiyabundles" class="nav-link">Hiya Bundle Accounts</router-link>
  </li>
  <li class="nav-item">
    <router-link to="/hiyapayg" class="nav-link">Hiya PAYG</router-link>
  </li>
  <li class="nav-item">
    <router-link to="/callbundles" class="nav-link">NetX Bundles</router-link>
  </li>
  <li class="nav-item">
    <router-link to="/accountbundles" class="nav-link">NetX Bundle Accounts</router-link>
  </li>
  <li class="nav-item">
    <router-link to="/smscontracts" class="nav-link">SMS Contracts</router-link>
  </li>
  <li class="nav-item">
    <router-link to="/accountsmscontracts" class="nav-link">SMS Contract Accounts</router-link>
  </li>
  </div>
  </li>

  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
      Usage Processing
    </a>
    <div class="dropdown-menu" text-nowrap>
  <li class="nav-item" v-if="isAdmin" text-nowrap>
    <router-link to="/cdrloads" text-nowrap class="nav-link">CDR Load</router-link>
  </li>
  <li class="nav-item" v-if="isAdmin" text-nowrap>
    <router-link to="/cdrloads/netxloadlogs" text-nowrap class="nav-link">NetX Load Logs</router-link>
  </li>
  <li class="nav-item" text-nowrap>
    <router-link to="/pricing" text-nowrap class="nav-link">Usage Pricing</router-link>
  </li>
  <li class="nav-item" text-nowrap>
    <router-link to="/pricing/summary" text-nowrap class="nav-link">Usage Pricing Summary</router-link>
  </li>
  </div>
  </li>

  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
      Bill Run
    </a>
    <div class="dropdown-menu" text-nowrap>
  <li class="nav-item">
    <router-link to="/orders" class="nav-link">Orders</router-link>
  </li>
  <li class="nav-item">
    <router-link to="/invoices" class="nav-link">Invoices</router-link>
  </li>
  <li class="nav-item">
    <router-link to="/exportcsvs" class="nav-link">Call Data</router-link>
  </li>
  <li class="nav-item" text-nowrap>
    <router-link to="/finalposts" text-nowrap class="nav-link">Final post</router-link>
  </li>
  <!-- <li class="nav-item">
    <router-link to="/invoiceemail" class="nav-link">Invoice Details</router-link>
  </li> -->
  </div>
  </li>
 <li class="nav-item dropdown" >
              <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                Report
              </a>

              <div class="dropdown-menu" text-nowrap>
                
                <li class="nav-item">
                  <router-link to="/reports/accountsclearup" class="nav-link">Account Clear Up</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/reports/baserates" class="nav-link">Base Rates</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/reports/billrunorders" class="nav-link">Bill Run Orders</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/reports/callcostmorethanbtl" class="nav-link">Calls With Call Cost More Than BTL</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/reports/carriers" class="nav-link">Carriers</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/reports/costcentres" class="nav-link">Cost Centres</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/reports/customisedrates" class="nav-link">Customised Rates</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/reports/rateslessthanbuy" class="nav-link">Cust Rates Less Than Buy Rates</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/reports/duplicatedloadedcalls" class="nav-link">Duplicated Loaded Calls</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/reports/duplicatedpricedcalls" class="nav-link">Duplicated Priced Calls</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/reports/accountsinbounds" class="nav-link">Inbound Billing References</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/reports/inboundcallanalysis" class="nav-link">Inbound Call Analysis</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/reports/groupaccounts" class="nav-link"> Group Accounts</router-link>
                </li>
                <li class="nav-item" text-nowrap v-if="isAdmin">
                  <router-link to="/reports/itemisedusage" text-nowrap class="nav-link">Itemised Usage</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/reports/netxgeoincomingsummary" class="nav-link">NetX GEO Incoming Summary</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/reports/netxgroups" class="nav-link">NetX Group Details</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/reports/netxpricing" class="nav-link">NetX Pricing</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/reports/accountsoutbounds" class="nav-link">Outbound Billing References</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/reports/productcatalogue" class="nav-link">Product Catalogue</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/reports/accountsrecurringcharges" class="nav-link">Recurring Charges Inventory</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/reports/singleaccounts" class="nav-link">Single Accounts</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/reports/suspendedcalls" class="nav-link">Suspended Usage</router-link>
                </li>
                 <li class="nav-item">
                  <router-link to="/reports/usagesummarybyaccount" class="nav-link">Usage Summary By Account</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/reports/usagesummarybybillingref" class="nav-link">Usage Summary By Billing Reference</router-link>
                </li>
                 <li class="nav-item" text-nowrap v-if="isAdmin">
                  <router-link to="/reports/usagesummarybycarrier" text-nowrap class="nav-link">Usage Summary By Carrier</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/reports/usagesummarybydestination" class="nav-link">Usage Summary By Charge Band</router-link>
                </li>
                 
              </div>
            </li>

  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown" v-if="isAdmin">
      Archive
    </a>
    <div class="dropdown-menu" text-nowrap>
  <li class="nav-item" text-nowrap>
    <router-link to="/histories/pricedcalls" text-nowrap class="nav-link">Archived Calls</router-link>
  </li>
  <li class="nav-item" text-nowrap>
    <router-link to="/invoices/archivedinvoices" text-nowrap class="nav-link">Archived Invoices</router-link>
  </li>
  </div>
  </li>
  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown" v-if="isAdmin">
      User
    </a>
    <div class="dropdown-menu" text-nowrap>
  <li class="nav-item" text-nowrap>
    <router-link to="/users" text-nowrap class="nav-link">Users</router-link>
  </li>
  </div>
  </li>
  <li class="nav-item">
    <a href="#" class="nav-link" @click="logout()">Logout</a>
  </li>

  <li class="nav-item dropdown">
    <div v-if="apiCallBillRuns">
      <a href="#" class="nav-link">Bill Run {{ apiCallBillRuns.BillRunNumber }}</a>
    </div>
  </li>
  <li class="nav-item dropdown">
    <div v-if="userfullname">
      <a href="#" class="nav-link">{{ this.userfullname }}</a>
    </div>
  </li>
   <li class="nav-item dropdown">
    <div v-if="userfullname">
      <a href="#" class="nav-link"><strong>{{ this.environment }}</strong></a>
    </div>
  </li>
  </ul>
  </div>
  </div>
  </nav>
  </div>
</template>

<script>
import {logout, isAdmin, userFullName} from '@/auth/AuthService'
export default {
  data() {
    return{
      isAdmin: setInterval(() => isAdmin(), 1000),
      userfullname: '',
      apiCallBillRuns: null,
      environment: process.env.VUE_APP_ENVIRONMENT
    }},
  name: "NavBar",
  methods: {
    getUser() {
      setTimeout(() => {
        this.userfullname = userFullName() + "  "
            }, 1000);
    },
    logout() {
      logout();
    },
    
    async callApi() {
      try {
        const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/bills/billruns`);

        console.log(data)
        // this.listCallBundle = data;
        this.apiCallBillRuns = data;
        this.executed = true;
      } catch (e) {
        this.listCallBundle = `Error: the server responded with '${e.response}'`;
      }
    }
  },
  created() {
    console.log('created')
    this.getUser();
    setTimeout(function () {
          this.callApi()}.bind(this), 1000)
    // this.callApi();

  },
};
</script>

<style>
#mobileAuthNavBar {
min-height: 125px;
justify-content: space-between;
}
li { white-space: nowrap; }
.listImage {
  margin: 2px;
  width: 120px;
  height: 28px;
  margin-right: 30px;
    }

/* .navbar {
  width: 100%;
} */
/* .navbar-nav li:hover > ul.dropdown-menu {
    display: block;
}
.dropdown-submenu {
    position:relative;
}
.dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top:-6px;
}
*/
/* rotate caret on hover
.dropdown-menu > li > a:hover:after {
    text-decoration: underline;
    transform: rotate(-90deg);
}
*/

</style>
