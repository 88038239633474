<template>

  <div>
    <p><u><strong>Single Accounts</strong></u></p>
    <br>
    <div class="result-block-container">

      <div :class="['result-block', executed ? 'show' : '']">
        <button v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.account-modal>Insert
          Account</button>&nbsp;&nbsp;&nbsp;
        <button v-if="isAdmin" type="button" class="btn btn-success btn-sm" v-b-modal.account-load-modal>Load
          Accounts</button>&nbsp;&nbsp;&nbsp;
        <a :href="'deletedaccounts'" target="_blank">
          <button v-if="isAdmin" type="button" class="btn btn-danger btn-sm">Deleted</button>
        </a>


        <br><br>
        <input type="search" v-model="filter">
        <!-- <b-pagination
        v-model="currentPage"
        :total-rows="filteredAccounts.length"
        :per-page="pageSize"
        aria-controls="my-table"
        ></b-pagination> -->

        <!-- <p class="mt-3">Current Page: {{ currentPage }}</p> -->
        <table class="table table-striped w-auto" id="apirecords">
          <thead>
            <tr>
              <th class="th-sm" scope="col" @click="sort('AccountNumber')">Account No</th>
              <th class="th-lg" scope="col" @click="sort('Name')" algin="left">Account Name</th>

              <!-- <th scope="col" @click="sort('SageAccount')">Sage Ref</th> -->
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th></th>
              <th scope="col" @click="sort('LastBillDate')" algin="left">Date Last Transferred</th>
              <th> </th>

            </tr>
          </thead>
          <tbody>
            <tr v-for="(account, index) in SortedapiAccounts" :key="index">
              <td>{{ account.AccountNumber }}</td>
              <td>{{ account.Name }}</td>

              <!-- <td>{{ account.SageAccount }}</td> -->
              <!-- <td><a :href="'/outbounds?account_id=' + account.account_id" >CLI</a></td> -->
              <!-- <td align="center"><a :href="'/outbounds?account_id=' + account.account_id" >{{account.outbounds}}</a></td>
              <td align="center"><a :href="'/inbounds?account_id=' + account.account_id" >{{account.inbounds}}</a></td>
              <td align="center"><a :href="'/costcentres?account_id=' + account.account_id" >{{account.costcentres }}</a></td>
              <td align="center"><a :href="'/recurringcharges?account_id=' + account.account_id" >{{account.recurringcharges}}</a></td> -->
              <td align="center"><a :href="'/costcentres?account_id=' + account.account_id" target="_blank">Cost
                  Centres</a></td>
              <td align="center"><a :href="'/outbounds?account_id=' + account.account_id" target="_blank">Outbounds</a></td>
              <td align="center"><a :href="'/inbounds?account_id=' + account.account_id" target="_blank">Inbounds</a>
              </td>

              <td align="center"><a :href="'/recurringcharges?account_id=' + account.account_id"
                  target="_blank">Recurring Charges</a></td>
              <td align="center"><a :href="'/accountchargebands?account_id=' + account.account_id" target="_blank">Dom
                  Rates</a></td>
              <td align="center"><a :href="'/accountcountries?account_id=' + account.account_id" target="_blank">Inter
                  Rates</a></td>
              <td align="center"><a :href="'/accounthistory?account_id=' + account.account_id"
                  target="_blank">History</a></td>
              <td>{{ account.LastBillDate }}</td>

              <td>
                <div class="btn-group" role="group">
                  <button type="button" class="btn btn-warning btn-sm" v-b-modal.account-update-modal
                    @click="editAccount(account)">
                    {{editButtonText}}
                  </button>
                  <p> </p>
                  <button v-if="isAdmin" type="button" class="btn btn-danger btn-sm" @click="onDeleteAccount(account)">
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <p align="center">
            <b-pagination v-model="currentPage" align="center" :total-rows="filteredAccounts.length"
              :per-page="pageSize" aria-controls="apirecords"></b-pagination>
            <!-- <button class="btn btn-outline-primary mr-1" @click="prevPage">Previous</button>
            <button class="btn btn-outline-primary" @click="nextPage">Next </button> -->
          </p>
        </div>
      </div>
    </div>

    <!-- LOAD modal -->
    <b-modal ref="loadAccountModal" id="account-load-modal" title="Load accounts" hide-footer size="lg">
      <b-form @submit="onLoadSubmit" @reset="onLoadReset" class="w-70">
        <b-form-row>
          <b-col>
            <p>Select a comma seperated file (.txt).</p>
            <p><strong>Update will only update invoice email address field</strong></p>
            <p><strong>Delete will fail if cost centres, outbounds or inbounds reside on entry</strong></p>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <b-form-file id="form-filename-input" type="file" size="sm" v-model="loadAccountForm.FileName" required
              placeholder="Select account file">
            </b-form-file>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col>
            <p></p>
          </b-col>
        </b-form-row>
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <b-button-group>
                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
                <b-button type="button" variant="info" v-on:click="download()">Download Template</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>

    <!-- ADD modal -->
    <b-modal ref="addAccountModal" id="account-modal" title="Insert a new account" hide-footer size="xl">
      <b-form @submit="onSubmit" @reset="onReset" class="w-200">
        <b-form-row>
          <b-col>
            <b-form-group id="form-customerno-group" label="Account Number" label-for="form-customerno-input"
              label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-customerno-input" type="text" size="sm" v-model="addAccountForm.AccountNumber"
                required placeholder="Enter account number">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-SageAccount-group" label="Sage Account" label-for="form-SageAccount-input"
              label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input id="form-SageAccount-input" type="text" size="sm" v-model="addAccountForm.SageAccount"
                required placeholder="Enter Sage Account">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-name-group" label="Account Name" label-for="form-name-input" label-cols="4"
              label-cols-sm="3" label-size="sm">
              <b-form-input id="form-tname-input" type="text" size="sm" v-model="addAccountForm.Name" required
                placeholder="Enter account name" w-20>
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-ContactName-group" label="Contact" label-for="form-ContactName-input" label-cols="4"
              label-cols-lg="3" label-size="sm">
              <b-form-input id="form-ContactName-input" type="text" size="sm" v-model="addAccountForm.ContactName"
                 placeholder="Enter ContactName">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Address1-group" label="Address1" label-for="form-Address1-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-Address1-input" type="text" size="sm" v-model="addAccountForm.Address1" required
                placeholder="Enter Address1">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-Email-group" label="Report Email" label-for="form-Email-input" label-cols="4"
              label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input id="form-Email-input" type="text" size="sm" v-model="addAccountForm.Email" 
                placeholder="Enter Report Email">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Address2-group" label="Address2" label-for="form-Address2-input" label-cols="4"
              label-cols-lg="3" label-size="sm">
              <b-form-input id="form-Address2-input" type="text" size="sm" v-model="addAccountForm.Address2" required
                placeholder="Enter Address2">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-invoiceemail-group" label="Invoice Email" label-for="form-invoiceemail-input"
              label-cols="4" label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input id="form-invoiceemail-input" type="text" size="sm" v-model="addAccountForm.InvoiceEmail"
              required placeholder="Enter Invoice Email">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-City-group" label="City" label-for="form-City-input" label-cols="6" label-cols-sm="3"
              label-size="sm">
              <b-form-input id="form-City-input" type="text" size="sm" v-model="addAccountForm.City" required
                placeholder="Enter City">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-NetXaccount_id-group" label="NetX Account ID" label-for="form-NetXaccount_id-input"
              label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input id="form-NetXaccount_id-input" type="text" size="sm" v-model="addAccountForm.NetXaccount_id"
                 placeholder="Enter NetX Account ID">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-County-group" label="County" label-for="form-County-input" label-cols="4"
              label-cols-lg="3" label-size="sm">
              <b-form-input id="form-County-input" type="text" size="sm" v-model="addAccountForm.County" 
                placeholder="Enter County">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-Carrier-group" label="Industry" label-for="form-InclusiveText-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="sm">
              <div>
                <IndustryDropDown  @industrytype-changed="industrytypeChange"></IndustryDropDown>
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Country-group" label="Country" label-for="form-Country-input" label-cols="4"
              label-cols-lg="3" label-size="sm">
              <b-form-input id="form-Country-input" type="text" size="sm" v-model="addAccountForm.Country" 
                placeholder="Enter Country">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-SaleAgent-group" label="Account Manager" label-for="form-SaleAgent-input"
              label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-SaleAgent-input" type="text" size="sm" v-model="addAccountForm.SaleAgent" required
                placeholder="Enter Account Manager">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-PostCode-group" label="Post Code" label-for="form-PostCode-input" label-cols="4"
              label-cols-lg="3" label-size="sm">
              <b-form-input id="form-PostCode-input" type="text" size="sm" v-model="addAccountForm.PostCode" required
                placeholder="Enter Post Code">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-NatMinCharge-group" label="National Min" label-for="form-NatMinCharge-input"
              label-cols="4" label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-NatMinCharge-input" type="text" size="sm" v-model="addAccountForm.NatMinCharge"
                required placeholder="Ener National Min">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-BTN-group" label="PO Number" label-for="form-BTN-input" label-cols="4"
              label-cols-lg="3" label-size="sm">
              <b-form-input id="form-BTN-input" type="text" size="sm" v-model="addAccountForm.BTN" 
                placeholder="Enter po number">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-NgnMinCharge-group" label="NGN Min" label-for="form-NgnMinCharge-input"
              label-cols="4" label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-NgnMinCharge-input" type="text" size="sm" v-model="addAccountForm.NgnMinCharge"
                required placeholder="Enter NGN Min">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-PaymentTerm-group" label="Payment Terms" label-for="form-PaymentTerm-input"
              label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-PaymentTerm-input" type="text" size="sm" v-model="addAccountForm.PaymentTerm"
                required placeholder="Enter Payment Terms">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-InterMinCharge-group" label="International Min" label-for="form-InterMinCharge-input"
              label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-InterMinCharge-input" type="text" size="sm" v-model="addAccountForm.InterMinCharge"
                required placeholder="Enter International Min">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>

          <b-col>
            <b-form-group id="form-Notes-group" label="Notes" label-for="form-Notes-input" label-cols="4"
              label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input id="form-Notes-input" type="text" size="sm" v-model="addAccountForm.Notes" 
                placeholder="Enter Notes">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col></b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-checkbox id="MaintenaceOnly" v-model="addAccountForm.MaintenaceOnly" name="MaintenaceOnly">
              Maintainance Only
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-checkbox id="DirectDebit" v-model="addAccountForm.DirectDebit" name="DirectDebit">
              Direct Debit
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-checkbox id="Taxable" v-model="addAccountForm.Taxable" name="Taxable">
              Taxable
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-checkbox id="ExportCalls" v-model="addAccountForm.ExportCalls" name="ExportCalls">
              Export Calls
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-checkbox id="Roundup" v-model="addAccountForm.Roundup" name="Roundup">
              Round Up
            </b-form-checkbox>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
            <p></p>
          </b-col>
        </b-form-row>
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <b-button-group>
                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>

    <!-- EDIT modal -->
    <b-modal ref="editAccountModal" id="account-update-modal" title="Update account" hide-footer size="xl">
      <b-form @submit="onSubmitUpdate" @reset="onResetUpdate" class="w-200">
        <b-form-row>
          <b-col>
            <b-form-group id="form-customerno-group" label="Account Number" label-for="form-customerno-input"
              label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-customerno-input" type="text" size="sm" v-model="editAccountForm.AccountNumber"
                readonly placeholder="Enter account number">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-SageAccount-group" label="Sage Account" label-for="form-SageAccount-input"
              label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input id="form-SageAccount-input" type="text" size="sm" v-model="editAccountForm.SageAccount"
                required placeholder="Enter Sage Account">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-name-group" label="Account Name" label-for="form-name-input" label-cols="4"
              label-cols-sm="3" label-size="sm">
              <b-form-input id="form-tname-input" type="text" size="sm" v-model="editAccountForm.Name" required
                placeholder="Enter account name" w-20>
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-ContactName-group" label="Contact" label-for="form-ContactName-input" label-cols="4"
              label-cols-lg="3" label-size="sm">
              <b-form-input id="form-ContactName-input" type="text" size="sm" v-model="editAccountForm.ContactName"
                 placeholder="Enter ContactName">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Address1-group" label="Address1" label-for="form-Address1-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-Address1-input" type="text" size="sm" v-model="editAccountForm.Address1" required
                placeholder="Enter Address1">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-Email-group" label="Report Email" label-for="form-Email-input" label-cols="4"
              label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input id="form-Email-input" type="text" size="sm" v-model="editAccountForm.Email" 
                placeholder="Enter Report Email">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Address2-group" label="Address2" label-for="form-Address2-input" label-cols="4"
              label-cols-lg="3" label-size="sm">
              <b-form-input id="form-Address2-input" type="text" size="sm" v-model="editAccountForm.Address2"
                placeholder="Enter Address2">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-invoiceemail-group" label="Invoice Email" label-for="form-nvoiceemail-input"
              label-cols="4" label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input id="form-nvoiceemail-input" type="text" size="sm" v-model="editAccountForm.InvoiceEmail"
              required placeholder="Enter Invoice Email">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-City-group" label="City" label-for="form-City-input" label-cols="6" label-cols-sm="3"
              label-size="sm">
              <b-form-input id="form-City-input" type="text" size="sm" v-model="editAccountForm.City" required
                placeholder="Enter City">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-NetXaccount_id-group" label="NetX Account ID" label-for="form-NetXaccount_id-input"
              label-cols="2" label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input id="form-NetXaccount_id-input" type="text" size="sm"
                v-model="editAccountForm.NetXaccount_id" placeholder="Enter NetX Account ID">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-County-group" label="County" label-for="form-County-input" label-cols="4"
              label-cols-lg="3" label-size="sm">
              <b-form-input id="form-County-input" type="text" size="sm" v-model="editAccountForm.County" 
                placeholder="Enter County">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-Carrier-group" label="Industry" label-for="form-InclusiveText-input" label-cols="2"
              label-cols-lg="3" label-size="sm" size="sm">
              <div>
                <IndustryDropDown v-bind:selected_industrytype="editAccountForm.IndustryTypeId"
                  @industrytype-changed="industrytypeChange"></IndustryDropDown>
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Country-group" label="Country" label-for="form-Country-input" label-cols="4"
              label-cols-lg="3" label-size="sm">
              <b-form-input id="form-Country-input" type="text" size="sm" v-model="editAccountForm.Country" 
                placeholder="Enter Country">
              </b-form-input>
            </b-form-group>
          </b-col>
            <b-col>
            <b-form-group id="form-SaleAgent-group" label="Account Manager" label-for="form-SaleAgent-input"
              label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-SaleAgent-input" type="text" size="sm" v-model="editAccountForm.SaleAgent" required
                placeholder="Enter Account Manager">
              </b-form-input>
            </b-form-group>
          </b-col> 
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-PostCode-group" label="Post Code" label-for="form-PostCode-input" label-cols="4"
              label-cols-lg="3" label-size="sm">
              <b-form-input id="form-PostCode-input" type="text" size="sm" v-model="editAccountForm.PostCode" required
                placeholder="Enter Post Code">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-NatMinCharge-group" label="National Min" label-for="form-NatMinCharge-input"
              label-cols="4" label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-NatMinCharge-input" type="text" size="sm" v-model="editAccountForm.NatMinCharge"
                required placeholder="Enter National Min">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-BTN-group" label="PO Number" label-for="form-BTN-input" label-cols="4"
              label-cols-lg="3" label-size="sm">
              <b-form-input id="form-BTN-input" type="text" size="sm" v-model="editAccountForm.BTN" 
                placeholder="Enter po number">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-NgnMinCharge-group" label="NGN Min" label-for="form-NgnMinCharge-input"
              label-cols="4" label-cols-lg="3" label-size="sm" size="sm">
              <b-form-input id="form-NgnMinCharge-input" type="text" size="sm" v-model="editAccountForm.NgnMinCharge"
                required placeholder="Enter NGN Min">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-PaymentTerm-group" label="Payment Terms" label-for="form-PaymentTerm-input"
              label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-PaymentTerm-input" type="text" size="sm" v-model="editAccountForm.PaymentTerm"
                required placeholder="Enter Payment Terms">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="form-InterMinCharge-group" label="International Min" label-for="form-InterMinCharge-input"
              label-cols="4" label-cols-lg="3" label-size="sm">
              <b-form-input id="form-InterMinCharge-input" type="text" size="sm"
                v-model="editAccountForm.InterMinCharge" required placeholder="Enter International Min">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group id="form-Notes-group" label="Notes" label-for="form-Notes-input" label-cols="4"
              label-cols-lg="3" label-size="sm" size="xs">
              <b-form-input id="form-Notes-input" type="text" size="sm" v-model="editAccountForm.Notes" 
                placeholder="Enter Notes">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col></b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-checkbox id="MaintenaceOnly" v-model="editAccountForm.MaintenaceOnly" name="MaintenaceOnly">
              Maintainance Only
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-checkbox id="DirectDebit" v-model="editAccountForm.DirectDebit" name="DirectDebit">
              Direct Debit
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-checkbox id="Taxable" v-model="editAccountForm.Taxable" name="Taxable">
              Taxable
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-checkbox id="ExportCalls" v-model="editAccountForm.ExportCalls" name="ExportCalls">
              Export Calls
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-checkbox id="Roundup" v-model="editAccountForm.Roundup" name="Roundup">
              Round Up
            </b-form-checkbox>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col>
            <input type="hidden" name="foo" :value="myHiddenValue">
          </b-col>
        </b-form-row>
        <br>
        <b-form-row>
          <b-col>
            <b-form-group id="form-LastBillDate-group" label="Date Last Transferred" label-for="form-LastBillDate-input"
              label-cols="2" label-cols-lg="4" label-size="sm" size="xs">
              <b-form-input readonly id="form-LastBillDate-input" type="text" size="sm"
                v-model="editAccountForm.LastBillDate">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>

          </b-col>
        </b-form-row>
        <div class="container">
          <div class="row" v-if="isAdmin">
            <div class="col text-center">
              <b-button-group>
                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import Alert from '../components/Alert.vue';
import Filter from '../components/Filter.vue';
import IndustryDropDown from "../components/IndustryDropDown.vue"

import {isAdmin, editButtonText, userFullName} from '@/auth/AuthService'
export default {
  Name: "Api",
  data() {
    return {
      isAdmin: isAdmin(),
      editButtonText: editButtonText(),
      userfullname:userFullName(),
      myHiddenValue:"",
      selectedIndustryType:"",
      buttonName : '',
      loadAccountForm: {
        FileName: ''
        },
      addAccountForm: {
        Name: '' ,
        AccountNumber: '' ,
        Address1: '' ,
        Address2: '' ,
        PostCode: '' ,
        City: '' ,
        County: '' ,
        Country: '' ,
        SaleAgent: '' ,
        PaymentTerm: 7,
        ContactName: '',
        SageAccount: '',
        NetXaccount_id: '' ,
        Email: '' ,
        InvoiceEmail: '',
        MaintenaceOnly: '',
        BTN: '' ,
        // PhoneUsers: 0,
        Taxable: true,
        Roundup: true,
        DirectDebit: '',
        // BonusEligible: '',
        // FaxToEmailCost: 0.05,
        // AudioConfPct: 25,
        // VideoConfPct: 25,
        NatMinCharge: 0.01,
        InterMinCharge: 0.01,
        NgnMinCharge: 0.01,
        ExportCalls: true,
        Notes:''
      },
      editAccountForm: {
        account_id : '',
        Name: '' ,
        AccountNumber: '' ,
        Address1: '' ,
        Address2: '' ,
        PostCode: '' ,
        City: '' ,
        County: '' ,
        Country: '' ,
        SaleAgent: '' ,
        PaymentTerm: '',
        ContactName: '',
        SageAccount: '',
        NetXaccount_id: '' ,
        Email: '' ,
        InvoiceEmail: '',
        MaintenaceOnly: '',
        BTN: '' ,
        // PhoneUsers: 0,
        Taxable: '',
        Roundup: '',
        DirectDebit: '',
        // BonusEligible: '',
        // FaxToEmailCost: '',
        // AudioConfPct: '',
        // VideoConfPct: '',
        NatMinCharge: '',
        InterMinCharge: '',
        NgnMinCharge: '',
        CreatedOn:'',
        CreatedBy:'',
        ModifiedOn:'',
        ModifiedBy:'',
        ExportCalls: '',
        LastBillDate:'',
        Notes:''
      },
      apiAccounts: null,
      executed: false,
      message: 'initial alert',
      currentSort:'Name',
      currentSortDir:'asc',
      pageSize:20,
      currentPage:1,
      filter:'',
      account_id:"shahabtest"
    };
  },

  computed:{
  filteredAccounts() {
    console.log('filtered account');
      return this.apiAccounts.filter(c => {
        if(this.filter == '') return true;
        if (c.SageAccount == null) c.SageAccount = '';
        if (c.AccountNumber == null) c.AccountNumber = '';
        return c.Name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 ||
               c.AccountNumber.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0||
               c.SageAccount.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
      })
    },
  SortedapiAccounts:function() {
    console.log('sort account');
    if (this.apiAccounts == null) return null;
    console.log ('in sort account....');
    console.log(this.currentSort)
    return this.filteredAccounts.slice().sort((a,b) => {
      let modifier = 1;
      if(this.currentSortDir === 'desc') modifier = -1;

      if (this.currentSort === 'outbounds') {
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
      }
      else if(this.currentSort === 'costcentres'){
        if(a[this.currentSort].length < b[this.currentSort].length) return -1 * modifier;
        if(a[this.currentSort].length > b[this.currentSort].length) return 1 * modifier;
      }
      else{
        if (a[this.currentSort] === null) return 1;
        if (b[this.currentSort] === null) return -1;
        if(a[this.currentSort].toLowerCase() < b[this.currentSort].toLowerCase()) return -1 * modifier;
        if(a[this.currentSort].toLowerCase() > b[this.currentSort].toLowerCase()) return 1 * modifier;
      }
      return 0;
    }).filter((row, index) => {
      let start = (this.currentPage-1)*this.pageSize;
      let end = this.currentPage*this.pageSize;
      if(index >= start && index < end) return true;
    });
  },
  },
  methods: {
    download: function() {
        console.log("in download")
        var data = "AccountNumber,AccountName,Address1,Address2,City,County,Country,PostCode,PONumber,PaymentTerms,SageAccount,NetXAccountID,Industry,AccountManager,Contact,ReportEmail,InvoiceEmail,Notes,NationalMin,NGNMin,InternationalMin,MaintenanceOnly,DirectDebit,Taxable,ExportCalls,RoundUp,Action"
        var fileURL = window.URL.createObjectURL(new Blob([data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        // const jsonData = encodeURIComponent('{"is_valid": true}')
        // this.myUrl = `data:text/plain;charset=utf-8,${jsonData}`
        // this.myFilename = 'example.json'
        fileLink.setAttribute('download', "UploadAccount.txt");
        document.body.appendChild(fileLink);

        fileLink.click();

      },
    industrytypeChange: function (e) {
        this.selectedIndustryType = e;
        if(e == ''){
            this.selectedIndustryType = null;
        }
       },
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.filteredAccounts.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    sort:function(s) {
    //if s == current sort, reverse
    if(s === this.currentSort) {
      this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
    }
    this.currentSort = s;
    },

    // *******************************************
    // List accounts
    // *******************************************
    async callApi(lablex) {

      console.log(lablex)
      console.log('getting the account data')
      try {
        document.body.style.cursor = "wait";
        // const { data } = await this.$http.get("https://dev03.btlcloud.net/api/v1/accounts", {
        const { data } = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/accounts`);
        document.body.style.cursor = 'auto';
        this.apiAccounts = data;
        console.log('got the account data from api')
        this.executed = true;
      } catch (e) {
        this.apiAccounts = `Error: the server responded with '${e.response}'`;
      }
    },

    // *******************************************
    // Delete account
    // *******************************************
    async removeAccount(accountid) {

      console.log("in removeAccount before")
      console.log(accountid)
      try {
        const { data } =  this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/accounts/${accountid}`).then(() => {
          this.message = 'account removed!';
          this.showMessage = true;
          this.apiAccounts = data;
          console.log("in removeAccount after")
          this.callApi("coming from delete");

        }).catch((err) => {
          console.log("in removeAccount error")
          console.error(err);
          if (err.response.status == 400) {
            console.log(err.response)
            console.log(err.response.data.message)
            alert("Problem deleting account. " + err.response.data.message)
          }
          // this.apiAccounts = `Error: the server responded with '${err.response}'`;
          // this.callApi();

        });

        // this.apiAccounts = data;
        // this.executed = true;
        // console.log('delete data is:')
        // console.log(data)
        // this.callApi()
      } catch (e) {
        this.apiAccounts = `Error: the server responded with '${e.response}'`;
      }
    },
    onDeleteAccount(account) {
      //  console.log(cli.OutboundId)
      if(confirm("Are you sure you want to delete this account?")){
        this.removeAccount(account.account_id);
      } 
    },

     // *******************************************
      // Load on submit
      // *******************************************
      onLoadSubmit(evt) {
        console.log('on load submit');
        evt.preventDefault();
        this.$refs.loadAccountModal.hide();
        const payload = {
          FileName: this.loadAccountForm.FileName
        };
        this.loadaccounts(payload);
        //this.initForm();
      },
      onLoadReset(evt) {
      evt.preventDefault();
      this.$refs.loadAccountModal.hide();
      this.initForm();
    },
    // Add cdrload method to post to API
    async loadaccounts(payload) {

      let formData = new FormData();
      console.log( 'in load products');
      console.log( payload.file);
      formData.append('file', payload.FileName);
      var url;
      
      url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/accounts/load`
      try {
        document.body.style.cursor = 'wait';
        const { data } =  this.$http.post(url, formData).then(() => {
          this.message = 'accounts loaded!';
          this.showMessage = true;
          document.body.style.cursor = 'auto';
          this.callApi();
        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated cdrload. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add cdrload. status code:  " + err.response.status)
            }
          console.error(err);
          document.body.style.cursor = 'auto';
          this.callApi();

        });

        // this.apicdrloads = data;
        // this.executed = true;
        console.log(data)
        //this.callApi()
      } catch (e) {
        this.apicdrloads = `Error: the server responded with '${e.response}'`;
      }
    },
    // Add account method to post to API
    async addAccount(payload) {

    //   const id_token = claims.__raw;
      console.log('in add account');
      console.log('payload is');
      console.log(payload);
      try {
        const { data } =  this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/accounts`, payload).then(() => {
          this.message = 'account added!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated account. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add account. status code:  " + err.response.status)
            }
          console.error(err);
          this.callApi();

        });

        // this.apiAccounts = data;
        // this.executed = true;
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiAccounts = `Error: the server responded with '${e.response}'`;
      }
    },
    initForm() {
      console.log('init form');
        this.addAccountForm.Name =  '';
        this.addAccountForm.AccountNumber =  '';
        this.addAccountForm.Address1 =  '';
        this.addAccountForm.Address2 =  '';
        this.addAccountForm.PostCode =  '';
        this.addAccountForm.City =  '';
        this.addAccountForm.County = '';
        this.addAccountForm.Country = '';
        this.addAccountForm.SaleAgent = '';
        this.addAccountForm.PaymentTerm = 7;
        this.addAccountForm.ContactName = '';
        this.addAccountForm.SageAccount = '';
        this.addAccountForm.NetXaccount_id = '';
        this.addAccountForm.Email = '';
        this.addAccountForm.InvoiceEmail = '';
        this.addAccountForm.MaintenaceOnly =  false;
        this.addAccountForm.BTN = '';
        // this.addAccountForm.PhoneUsers =  10;
        this.addAccountForm.Taxable =  true;
        this.addAccountForm.Roundup =  true;
        this.addAccountForm.DirectDebit =  false;
        // this.addAccountForm.BonusEligible =  false;
        // this.addAccountForm.FaxToEmailCost =  0.05;
        // this.addAccountForm.AudioConfPct =  15;
        // this.addAccountForm.VideoConfPct =  25;
        this.addAccountForm.NatMinCharge =  0.01;
        this.addAccountForm.InterMinCharge =  0.01;
        this.addAccountForm.NgnMinCharge =  0.01;
        this.addAccountForm.ExportCalls =  true;
        this.addAccountForm.Notes = '';

    },
    // *******************************************
    // Add account on submit
    // *******************************************
    onSubmit(evt) {
      console.log('on submit');
      evt.preventDefault();
      this.$refs.addAccountModal.hide();
      console.log('MaintenaceOnly');
      // ***********this need to be looked at again as the check**************
      // box value is not presented when not selected
      if(this.addAccountForm.MaintenaceOnly== "") this.addAccountForm.MaintenaceOnly= false;
      if(this.addAccountForm.MaintenaceOnly!= "") this.addAccountForm.MaintenaceOnly= true;
      if(this.addAccountForm.Taxable== "") this.addAccountForm.Taxable= false;
      if(this.addAccountForm.Taxable!= "") this.addAccountForm.Taxable= true;
      if(this.addAccountForm.Roundup== "") this.addAccountForm.Roundup= false;
      if(this.addAccountForm.Roundup!= "") this.addAccountForm.Roundup= true;
      if(this.addAccountForm.DirectDebit== "") this.addAccountForm.DirectDebit= false;
      if(this.addAccountForm.DirectDebit!= "") this.addAccountForm.DirectDebit= true;
      // if(this.addAccountForm.BonusEligible== "") this.addAccountForm.BonusEligible= false;
      if(this.addAccountForm.BonusEligible!= "") this.addAccountForm.BonusEligible= true;
      if(this.addAccountForm.ExportCalls== "") this.addAccountForm.ExportCalls= false;

      const payload = {
        Name: this.addAccountForm.Name,
        AccountNumber: this.addAccountForm.AccountNumber,
        Address1: this.addAccountForm.Address1,
        Address2: this.addAccountForm.Address2,
        PostCode: this.addAccountForm.PostCode,
        City: this.addAccountForm.City,
        County:this.addAccountForm.County,
        Country:this.addAccountForm.Country,
        SaleAgent:this.addAccountForm.SaleAgent,
        PaymentTerm:this.addAccountForm.PaymentTerm,
        ContactName:this.addAccountForm.ContactName,
        SageAccount:this.addAccountForm.SageAccount,
        NetXaccount_id:this.addAccountForm.NetXaccount_id,
        Email:this.addAccountForm.Email,
        InvoiceEmail : this.addAccountForm.InvoiceEmail,
        MaintenaceOnly: this.addAccountForm.MaintenaceOnly,
        BTN:this.addAccountForm.BTN,
        // PhoneUsers: this.addAccountForm.PhoneUsers,
        Taxable: this.addAccountForm.Taxable,
        Roundup: this.addAccountForm.Roundup.checked,
        DirectDebit:this.addAccountForm.DirectDebit,
        // BonusEligible: this.addAccountForm.BonusEligible,
        // FaxToEmailCost: this.addAccountForm.FaxToEmailCost,
        // AudioConfPct: this.addAccountForm.AudioConfPct,
        // VideoConfPct: this.addAccountForm.VideoConfPct,
        IndustryTypeId: this.selectedIndustryType,
        NatMinCharge: this.addAccountForm.NatMinCharge,
        InterMinCharge: this.addAccountForm.InterMinCharge,
        NgnMinCharge: this.addAccountForm.NgnMinCharge,
        ExportCalls: this.addAccountForm.ExportCalls,
        Notes:this.addAccountForm.Notes
      };
      this.addAccount(payload);
      this.initForm();
    },
    onReset(evt) {
      evt.preventDefault();
      this.$refs.addAccountModal.hide();
      this.initForm();
    },
  //
    editAccount(account) {
      console.log('editAccount');
      console.log(account);
      console.log(account.account_id);
      this.editAccountForm = account;
      this.callApi()
    },

    // *******************************************
    // Update account on submit
    // *******************************************
    onSubmitUpdate(evt) {
      console.log('in onSubmitUpdate');
      console.log('account_id');
      evt.preventDefault();
      this.$refs.editAccountModal.hide();
      // ***********this need to be looked at again as the check**************
      // box value is not presented when not selected
      if(this.editAccountForm.MaintenaceOnly== "") this.editAccountForm.MaintenaceOnly= false;
      if(this.editAccountForm.MaintenaceOnly!= "") this.editAccountForm.MaintenaceOnly= true;
      if(this.editAccountForm.Taxable== "") this.editAccountForm.Taxable= false;
      if(this.editAccountForm.Taxable!= "") this.editAccountForm.Taxable= true;
      if(this.editAccountForm.Roundup== "") this.editAccountForm.Roundup= false;
      if(this.editAccountForm.Roundup!= "") this.editAccountForm.Roundup= true;
      if(this.editAccountForm.DirectDebit== "") this.editAccountForm.DirectDebit= false;
      if(this.editAccountForm.DirectDebit!= "") this.editAccountForm.DirectDebit= true;
      // if(this.editAccountForm.BonusEligible== "") this.editAccountForm.BonusEligible= false;
      if(this.editAccountForm.ExportCalls!= "") this.editAccountForm.ExportCalls= true;

      const payload = {
        Name: this.editAccountForm.Name,
        AccountNumber: this.editAccountForm.AccountNumber,
        Address1: this.editAccountForm.Address1,
        Address2: this.editAccountForm.Address2,
        PostCode: this.editAccountForm.PostCode,
        City: this.editAccountForm.City,
        County:this.editAccountForm.County,
        Country:this.editAccountForm.Country,
        SaleAgent:this.editAccountForm.SaleAgent,
        PaymentTerm:this.editAccountForm.PaymentTerm,
        ContactName:this.editAccountForm.ContactName,
        SageAccount:this.editAccountForm.SageAccount,
        NetXaccount_id:this.editAccountForm.NetXaccount_id,
        Email:this.editAccountForm.Email,
        InvoiceEmail : this.editAccountForm.InvoiceEmail,
        MaintenaceOnly: this.editAccountForm.MaintenaceOnly,
        BTN:this.editAccountForm.BTN,
        // PhoneUsers: this.editAccountForm.PhoneUsers,
        Taxable: this.editAccountForm.Taxable,
        Roundup: this.editAccountForm.Roundup,
        DirectDebit:this.editAccountForm.DirectDebit,
        // BonusEligible: this.editAccountForm.BonusEligible,
        // FaxToEmailCost: this.editAccountForm.FaxToEmailCost,
        // AudioConfPct: this.editAccountForm.AudioConfPct,
        // VideoConfPct: this.editAccountForm.VideoConfPct,
        NatMinCharge: this.editAccountForm.NatMinCharge,
        InterMinCharge: this.editAccountForm.InterMinCharge,
        NgnMinCharge: this.editAccountForm.NgnMinCharge,
        ExportCalls: this.editAccountForm.ExportCalls,
        IndustryTypeId: this.selectedIndustryType,
        Notes: this.editAccountForm.Notes
      };
      this.updateAccount(payload, this.editAccountForm.account_id);
      this.callApi()
    },

    async updateAccount(payload, accountid) {

      console.log('in update account');
      console.log(payload);
      try {
        const { data } =  this.$http.put(`${process.env.VUE_APP_API_BASE_URL}/api/v1/accounts/${accountid}`, payload).then(() => {
          this.message = 'account updated!';
          this.showMessage = true;
          this.callApi();

        }).catch((err) => {
          if(err.response.status == 409) {
            alert("Duplicated account. status code:  " + err.response.status)
            }
          if(err.response.status != 409) {
            alert("Problem with add account. status code:  " + err.response.status)
            }

          console.error(err);
          this.callApi();

        });

        // this.apiAccounts = data;
        // this.executed = true;
        console.log('delete data is:')
        console.log(data)
        this.callApi()
      } catch (e) {
        this.apiAccounts = `Error: the server responded with '${e.response}'`;
      }
    },
    onResetUpdate(evt) {
      evt.preventDefault();
      this.$refs.editAccountModal.hide();
      this.initForm();
      this.getAccounts(); // why?
    },
  },
  created() {
    console.log('created')
    this.callApi();

  },
  components: {
    alert: Alert,
    listfilter:Filter,
    IndustryDropDown: IndustryDropDown
  },
};
</script>
